// @ts-ignore
/* eslint-disable */
import request from '../../utils/request';

/** 创建主订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /payment/order/main */
export async function paymentOrderMainUsingPOST(
  body: API.CreateMainOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreateMainOrderResp>('/payment/order/main', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建支付子订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /payment/order/sub */
export async function paymentOrderSubUsingPOST(
  body: API.CreatePaymentSubOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreatePayableSubOrderResp>('/payment/order/sub', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取支付方式 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /payment/pay-type */
export async function paymentPayTypeUsingPOST(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.paymentPayTypeUsingPOSTParams,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultListPaymentTypeResp>('/payment/pay-type', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建营销订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /payment/promotion */
export async function paymentPromotionUsingPOST(
  body: API.CreatePromotionOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreatePayableSubOrderResp>('/payment/promotion', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建营销H5订单 # **获取AccessToken** 
<a href='http://auth-service-beta2.shrise.cn/doc.html#/default/token-controller/generateUserAccessTokenUsingPOST' target='_blank'>点击此处获取测试站用户AccessToken</a>参数示例: 
  ```
{
  "productType": 171,
  "userId": 240
}
  ```
 POST /payment/promotion/h5 */
export async function paymentPromotionH5UsingPOST(
  body: API.CreatePromotionOrderReq,
  options?: { [key: string]: any },
) {
  return request<API.BaseResultCreatePayableSubOrderResp>('/payment/promotion/h5', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
